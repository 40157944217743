
import {ref} from "vue";

/**
 * 显示微信分享方法
 */
export default {
    name: 'fenXiang',
    setup() {
        const status = ref(false)

        return {
            status
        }
    }
}

