<template>
  <footer-gou-mai-btn @lol-click="editBtn" :btn-list="btnList"/>
  <lian-xi-ke-fu ref="keFu"/>
</template>

<script>
import LiuYan from "@/views/open/LiuYan";
import http from "../../api/http";
import FooterGouMaiBtn from "../../components/btn/footerGouMaiBtn";
import LianXiKeFu from "./LianXiKeFu";

export default {
  name: 'contactFooterNav',
  components: {
    LianXiKeFu,
    FooterGouMaiBtn,
    LiuYan,
  },
  data() {
    return {
      btnList: [
        {
          icon: 'iconfont tu-biao icon-dianhua',
          title: '客服电话',
          type: '客服电话'
        },
        {
          icon: 'iconfont icon-kefuxiaoxi',
          title: '在线留言',
          type: '留言反馈'
        },
        {
          icon: 'iconfont icon--kefu',
          title: '在线咨询',
          type: '在线客服'
        }
      ]
    }
  },
  methods: {
    editBtn(item) {
      this.$refs.keFu.onSelect({type: item.type})

    }

  }
}
</script>

<style scoped lang="less">
@import "../../assets/basis_css/style/var.less";

@div-height: 55px;
.footer-list {
  z-index: 17;
  height: 47px;
  background-color: #fff;
  box-shadow: 0 -2px 4px #ecdddd;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: flex-end;
  position: fixed;
  bottom: -1px;
  width: 100%;

  .ne-rong {
    text-align: center;
    //flex: 1;
    width: 100%;
    position: relative;
    display: flex;

    .ne-rong-div:first-child,
    .ne-rong-div:nth-child(2) {
      flex: 1;
    }

    .ne-rong-div:last-child {
      flex: 2;
      background-color: #ef9135;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    /*  .zhe-zhao.faBu {
        position: absolute;
        width: 100%;
        height: 30px;
        background-color: @white;
        top: 0;
        left: 0;
        right: 0;
      }*/

    .ne-rong-div {
      padding-bottom: 5px;
      color: rgb(74, 64, 150);

      &.faBu {
        position: absolute;
        top: -85px;
        border-radius: 100px;
        left: 0;
        right: 0;
        background-color: @white;
        width: 58px;
        height: 58px;
        margin: auto;
        box-shadow: 0 -2px 4px #f3e9e9;
        z-index: 10;

        .icon {
          margin-top: 10px;
          margin-left: 10px;
          width: 38px;
          height: 38px;
          background-color: @blue;
          border-radius: 100px;
          line-height: 54px;
          text-align: center;
        }

        .name {
          color: @blue !important;
          margin-top: 5px;
          font-size: 14px;
        }
      }

      .name {
        font-size: 14px;
      }
    }

  }
}

.fu-dong {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.fixed {
  height: @div-height+10;
}

</style>
