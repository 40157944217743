<template>
  <template v-if="is_show">
    <div class="kuai-jie" :style="{'bottom':bottom}">
      <van-row gutter="10">
        <van-col span="12" class="van-ellipsis">
          <a
              @click="tiao_zhuan(kuaiJie.pre)"
              href="javascript:"
              v-if='_.get(kuaiJie,"pre.id")'
          >
            上一个：{{ _.get(kuaiJie, "pre.name", '--') }}
          </a>
        </van-col>
        <van-col span="12" class="text-right van-ellipsis">
          <a
              @click="tiao_zhuan(kuaiJie.next)"
              href="javascript:"
              v-if='_.get(kuaiJie,"next.id")'
          >
            下一个：{{ _.get(kuaiJie, "next.name", '--') }}
          </a>
        </van-col>
      </van-row>
    </div>
    <div style="height: 40px;"></div>
  </template>

</template>

<script>
export default {
  name: 'KuanJieTiaoZhuan'
}
</script>
<script setup>
import {defineProps, onMounted, defineEmit, computed, ref} from 'vue'
import http from "@/api/http";
import _ from 'lodash'

const emit = defineEmit(['lol-click'])
let props = defineProps({
  bottom: {
    type: Number,
    default() {
      return '58px'
    }
  },
  url: String
})
let kuaiJie = ref({})
onMounted(() => {
  http.get(props.url).then(da => {
    if (da.code === 1) {
      kuaiJie.value = da.data
    }
  })
})

//两个都没有 就不显示  false= 隐藏
let is_show = computed(() => {
  return !(_.toInteger(_.get(kuaiJie.value, "next.id")) === 0 && _.toInteger(_.get(kuaiJie.value, "pre.id")) === 0)
})


function tiao_zhuan(item) {
  emit('lol-click', item)
}


</script>

<style scoped lang="less">
@import "~@/assets/basis_css/style/var.less";

.kuai-jie {
  position: fixed;
  width: 100%;
  background-color: @white;
  padding: 10px;
  border-bottom: 1px solid @gray-2;
  box-shadow: @yin-ying-up;
}
</style>
